/**
 * Add here all the left pane configurations for each route
 */
import { companyConfig } from 'globalConfigApp'

const params = {
  payment: {
    image: ['valuation/valuation-step-4.jpg'],
    title: `Disfruta de beneficios exclusivos que te ofrece ${companyConfig.name}`,
    texts: [
      'Administre su lista de propiedades',
      'Perfila tu lista de compradores y ofertas',
      'Todo esto y mas en tiempo real'
    ]
  },
  confirmyouremail: {
    image: ['confirm-your-email/confirm-email-left-pane.jpg'],
    title: `¿Por qué elegir a ${companyConfig.name}?`,
    texts: [
      'Administre su lista de propiedades',
      'Perfila tu lista de compradores y ofertas',
      'Todo esto y mas en tiempo real'
    ]
  },
  login: {
    image: ['login/left-pane.jpg'],
    title: `${companyConfig.name}  - La Alianza inmobiliaria más innovadora de México.`,
    texts: [
      'Capta más propiedades',
      'Genera más contactos',
      'Gestiona todos tus clientes',
      'Conviertete en un experto local inmobiliario',
      `Gana más comisiones con ${companyConfig.name}`
    ]
  },
  signup: {
    image: ['login/left-pane.jpg'],
    title: `Con ${companyConfig.name} tienes el control total sobre tu proceso de venta o renta.`,
    texts: [
      'Administra tu lista de propiedades',
      'Perfila tu lista de compradores y ofertas',
      'Todo esto y mas en tiempo real'
    ]
  },
  notfound: {
    image: ['confirm-your-email/confirm-email-left-pane.jpg'],
    title: `¿Por qué elegir a ${companyConfig.name}?`,
    texts: [
      'Administre su lista de propiedades',
      'Perfila tu lista de compradores y ofertas',
      'Todo esto y mas en tiempo real'
    ]
  },
}

export default function leftPaneConfiguration(route) {
  const config = params[String(route).replace(/[^\w+]/g, '').toLowerCase()]

  if (!config) {
    return params['notfound']
  }

  return config
}
