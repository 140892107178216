import React from 'react'
import { image } from 'services'
import { colors } from 'theme'
import config from 'config'

import Layout from 'components/Layout'
import LeftThird from 'components/LeftThird'
import LeftThirdIAD from 'components/Forms/Login/IADLogin/LeftThird'
import configuration from './leftPaneConfiguration'
import styles from './styles'

const COMPANY_THEME = config('COMPANY_THEME')
const IS_IAD_THEME = COMPANY_THEME !== 'NEXIMO'

const SplitPageHOC = (WrappedComponent, marginTop='70px') => {
  return class SplitPage extends WrappedComponent {
    static defaultProps = {
      layoutProps: {}
    }

    constructor(props) {
      super(props)

      this.leftPaneConfig = configuration(this.props.url.pathname)
    }

    render() {
      const { leftPaneConfig } = this
      const { query, pathname } = this.props.url

      return (
        <Layout urlData={{ query, pathname }} {...this.props.layoutProps}>
          {IS_IAD_THEME ? (
            <LeftThirdIAD
              image={image(leftPaneConfig.image)}
              title={leftPaneConfig.title}
              texts={leftPaneConfig.texts}
              style={{ position: 'fixed', width: '33%' }}
            />
          ) : (
            <LeftThird
              image={image(leftPaneConfig.image)}
              title={leftPaneConfig.title}
              texts={leftPaneConfig.texts}
              style={{ position: 'fixed', width: '33%' }}
            />
          )}
          <div className="split-page-container">
            <div className="split-page-body" style={{ marginTop }}>{super.render()}</div>
          </div>
          <style jsx>{styles}</style>
        </Layout>
      )
    }
  }
}

export default SplitPageHOC
