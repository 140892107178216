/**
    * Migrated from 'components/LeftThird/styles.js' to avoid override neximo styles
    * This component will be used in the migration so it needs to have IAD look and feel
    * Sep, 2023
 */
import { colors, fonts } from 'theme'
import css from 'styled-jsx/css'

export default css`
  .container {
    height: calc(100vh);
    width: 100%;
    background-size: cover;
    background-position: center top;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 3rem;
    padding-bottom: 4rem;
    color: ${colors.white};
  }

  .title {
    color: red;
  }
`

export const leftThirdTitle = css`
  .left-third-title {
    font-size: 1.75rem;
    color: ${colors.white};
    margin-bottom: 2rem;
  }
`

export const leftThirdText = css`
  .left-third-text {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.7rem;
  }

  .text {
    margin-top: 6px;
    margin-left: 8px;
  }
`