/**
    * Migrated from 'components/LeftThird/index.js' to avoid override neximo styles
    * This component will be used in the migration so it needs to have IAD look and feel
    * Sep, 2023
 */
import React, { PureComponent } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { image } from 'services'
import { IonIcon } from 'controls'
import Link from 'next/link'
import colors from 'theme/colors'
import { companyConfig } from 'globalConfigApp'

import { iadProvitionalColors } from 'theme/colors'

import styles, { leftThirdTitle, leftThirdText } from './styles'

class LeftThird extends PureComponent {
  static propTypes = {
    image: PropTypes.string,
    hideOnMobile: PropTypes.bool.isRequired,
    style: PropTypes.object.isRequired,
    showLogo: PropTypes.bool.isRequired,
    texts: PropTypes.array,
  }

  static defaultProps = {
    image: null,
    hideOnMobile: true,
    style: { width: '33%' },
    showLogo: true,
    texts: [],
  }

  renderLogo = () => {
    return (
        <a href="/" target="_blank">
          <img
            style={{
              position: 'absolute',
              margin: '3rem',
              maxWidth: '5rem',
              maxHeight: '5rem',
              zIndex: 1,
            }}
            src={image('IAD-White.png')}
            alt={companyConfig.name}
          />
        </a>
    )
  }

  renderText = (textItem, index) => (
    <div key={index} className="left-third-text">
      <IonIcon
        style={{ color: iadProvitionalColors.primary }}
        icon="md-checkmark"
      />
      <span className="text">{textItem}</span>
      <style jsx>{leftThirdText}</style>
    </div>
  )

  render() {
    const { props } = this
    const { hideOnMobile } = props
    const style = {
      backgroundImage: `url(${image('bg-login-iad.png')})`,
      objectFit: "cover",
    }

    return (
      <div style={props.style} className={classnames({ hideOnMobile })}>
        {props.showLogo && this.renderLogo()}
        <div className="container" style={style}>
          {props.title && (
            <div className="left-third-title">
              Disfruta de los beneficios exclusivos que te ofrece <b>iad</b>{' '}
              México
            </div>
          )}
          {this.props.texts.map(this.renderText)}
          {React.Children.map(props.children, this.renderText)}
          <style jsx>{styles}</style>
          <style jsx>{leftThirdTitle}</style>
        </div>
      </div>
    )
  }
}

export default LeftThird
